import { VALUES } from './constants'

const OVERRIDEN_STYLES = [
  {
    selector: '.overlay-close::after',
    property: 'content',
    icon: 'fi-rr-chevron-down',
  },
  {
    selector: '.overlay-open::after',
    property: 'content',
    icon: 'fi-rr-chevron-up',
  },
  {
    selector: 'select',
    property: 'background-image',
    icon: 'fi-rr-arrow-down-right',
  },
  {
    selector: '.daysToGoSlider .input-range__slider',
    property: 'mask-image',
    icon: 'fi-rr-marker',
  },
]

const generateCSSString = (selector, property, url) => {
  const cssDynamicPropertyValue = `
    ${selector} {
      ${property}: url("${url});
    }`
  return cssDynamicPropertyValue
}

export const appendIconOverrideStyleSheet = (url, icons) => {
  const overridenStyles = document.createElement('style')
  let styleString = VALUES.empty

  for (let style of OVERRIDEN_STYLES)
    styleString += generateCSSString(
      style.selector,
      style.property,
      url + icons[style.icon]
    )

  overridenStyles.innerText = styleString
  document.head.appendChild(overridenStyles)
}

export const appendStylesOverrideStyleSheet = (styles, stylesheetId) => {
  const overridenStyles = document.createElement('style')
  overridenStyles.setAttribute('id', stylesheetId)
  overridenStyles.innerText = styles
  document.head.appendChild(overridenStyles)
}

export const getPrimaryColorCode = () => {
  const primaryColorCssVarriableName = '--primary'

  const primaryColorValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(primaryColorCssVarriableName)

  const primaryColorCode = primaryColorValue.replace(
    VALUES.singleQuoteMark,
    VALUES.empty
  )

  return primaryColorCode
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cleanStorage from '../../setup/cleanStorage'
import { TRANSLATIONS } from '../../util/constants'
import {
  getAppVersion,
  getDeviceLanguageCode,
  isNativePlatform,
} from '../../util/native/nativeUtils'
import i18n from '../../setup/i18n'
import SpinnerOverlay from '../../components/Spinner/SpinnerOverlay'
import { sleep } from '../../util/sleep'

const theme = {
  primary: 'white',
  secondary: '#3c3e4b',
}

const loadDelayTime = 500

const titleClicksInterval = 3000
const titleClicksCountNeeded = 5
let titleClickCount = 0

function showError(error) {
  let errorString

  try {
    errorString = JSON.stringify(error)
  } catch (err) {
    errorString = error.message
  }

  alert(errorString)
}

export default function LoadErrorPage({ initializeWebapp, initializeError }) {
  const { t } = useTranslation(TRANSLATIONS.LOAD_ERROR_PAGE)
  const [loading, setLoading] = useState(false)
  const [appVersion, setAppVersion] = useState(null)

  async function onRetry() {
    cleanStorage()

    setLoading(true)
    await initializeWebapp()
    await sleep(loadDelayTime)
    setLoading(false)
  }

  function onTitleClick() {
    if (titleClickCount === 0) {
      setTimeout(() => {
        titleClickCount = 0
      }, titleClicksInterval)
    }

    titleClickCount += 1

    if (titleClickCount === titleClicksCountNeeded) {
      showError(initializeError)
    }
  }

  useEffect(async () => {
    if (isNativePlatform()) {
      setAppVersion(await getAppVersion())

      const languageCode = await getDeviceLanguageCode()
      i18n.changeLanguage(languageCode)
    }
  }, [])

  return (
    <div
      className="w-screen h-screen p-6 flex flex-col items-center text-center"
      style={{ backgroundColor: theme.secondary, color: theme.primary }}
    >
      <div className="flex-grow flex flex-col gap-y-14 justify-center items-center">
        <h1 className="text-4xl" onClick={onTitleClick}>
          {t('title')}
        </h1>

        <h2>{t('text')}</h2>

        <img
          src="/assets/images/warning-sign.svg"
          alt="warning sign"
          className="h-28"
        />

        <button onClick={onRetry} className="underline text-lg">
          {t('retryButton')}
        </button>
      </div>

      {appVersion && <p>v{appVersion}</p>}

      <SpinnerOverlay show={loading} />
    </div>
  )
}

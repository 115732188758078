import { trackingLiterals } from '../setup/tracking/trackingLiterals'
import {
  getUser,
  getCampaign,
  getPageFixParams,
  trackEvent,
  buildCommerceItem,
} from '../setup/tracking/trackingFunctions'

export const tagManagerCarAddFavourite = (
  index,
  vehicle,
  isFavourite,
  subscriberData
) => {
  const ecommerceItem = {
    items: [buildCommerceItem({ vehicle, index })],
  }

  const eventName = isFavourite
    ? trackingLiterals.events.add_to_favorites
    : trackingLiterals.events.remove_from_favorites

  const trackValues = {
    dataLayer: {
      event: eventName,
      page: {
        category: trackingLiterals.page.category.carlist,
        type: trackingLiterals.page.type.list,
        name: trackingLiterals.page.name.carlist,
        ...getPageFixParams(),
      },

      user: getUser(subscriberData),

      campaign: getCampaign(),

      ecommerce: ecommerceItem,
    },
  }

  trackEvent(trackValues)
}

import { getGaClient, trackEvent } from '../setup/tracking/trackingFunctions'
import { trackingLiterals } from '../setup/tracking/trackingLiterals'
import settings from '../settings'

export const tagManagerTrackAction = (action, cta) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.page.category.interaction,
        action: action,
        label: cta,
        ga_client: getGaClient(),
        country: settings.country,
      },
    },
  }
  trackEvent(trackValues)
}

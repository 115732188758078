import classNames from 'classnames'

const defaultClassNames = 'cursor-pointer'
const emptyClassNames = ''

const getUnderlineClassNames = (underline) => {
  const underlineClassNames = 'underline'

  return underline ? underlineClassNames : emptyClassNames
}

const getHoverUnderlineClassNames = (hoverUnderline) => {
  const hoverUnderlineClassNames = 'hover:underline'

  return hoverUnderline ? hoverUnderlineClassNames : emptyClassNames
}

const getTextColorClassNames = (alwaysPrimaryColor) => {
  const textColorClassNames = 'text-primary'

  return alwaysPrimaryColor ? textColorClassNames : emptyClassNames
}

const getHoverTextColorClassNames = (hoverColor) => {
  const hoverTextColorClassNames = 'hover:text-primary'

  return hoverColor ? hoverColor : hoverTextColorClassNames
}

export default function TextClickable({
  text,
  onClick,
  alwaysPrimaryColor,
  underline,
  hoverUnderline,
  hoverColor,
  ...props
}) {
  const textClassNames = classNames(
    defaultClassNames,
    getHoverTextColorClassNames(hoverColor),
    getTextColorClassNames(alwaysPrimaryColor),
    getUnderlineClassNames(underline),
    getHoverUnderlineClassNames(hoverUnderline)
  )

  return (
    <span className={textClassNames} onClick={onClick} {...props}>
      {text}
    </span>
  )
}

import { getCountryFromPath } from './urlHelper'
import settings from '../settings'

const fallBackCountryCode = 'es'

export function getCurrentCountryName() {
  const pathCountryCode = getCountryFromPath()
  return settings?.availableTenantsNavigation?.find(
    (item) => item.code.toUpperCase() === pathCountryCode.toUpperCase()
  )?.keyName
}

export function getDefaultCountryCode() {
  const settingsCountryCode = settings.country.toLowerCase()
  const countryCode = settingsCountryCode || fallBackCountryCode
  return countryCode
}

import { trackingLiterals } from '../setup/tracking/trackingLiterals'

import {
  getPageFixParams,
  trackEvent,
  getGaClient,
} from '../setup/tracking/trackingFunctions'

import { getLastBillStatus } from '../util/billingHelper'


export const tagManagerSwipeCarousel = (
  label,
  subscriberData,
  bills
) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.button,
        label: trackingLiterals.events.actions.swipe + label,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

export const tagManagerSwipeCarouselClose = (
  subscriberData,
  bills
) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.swipeFrame,
        label: trackingLiterals.events.actions.close,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

export const tagManagerActionCTA = (subscriberData, bills) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.swipeFrame,
        label: trackingLiterals.events.actions.ctaCarousel,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

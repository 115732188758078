import get from 'lodash/get'
import LOCAL_FILTER_QUERY from '../filters/localFilterQuery'
import {
  get as readVehicleFilterCache,
  getDefaultData,
} from '../setup/cache/vehicleFilters'

export default function refreshVehicleFilterCache(client) {
  try {
    const data = readVehicleFilterCache()
    client.writeQuery({
      query: LOCAL_FILTER_QUERY,
      data: {
        filters: {
          ...getDefaultData().filters,
          ...get(data, 'filters', {}),
        },
      },
    })
  } catch {
    // If cache is bad, just use default filters
    client.writeQuery({
      query: LOCAL_FILTER_QUERY,
      data: {
        filters: getDefaultData().filters,
      },
    })
  }
}

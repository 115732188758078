import { ApolloClient, InMemoryCache, from, gql } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import LOCAL_FILTER_QUERY from '../filters/localFilterQuery'
import settings from '../settings'
import refreshVehicleFilterCache from '../util/refreshVehicleFilterCache'
import { loadToken } from '../util/tokenManager'
import { getAppId, getPlatform } from '../util/multiTenantHelper'
import { set as cacheVehicleFilters } from './cache/vehicleFilters'
import { createUploadLink } from 'apollo-upload-client'
import { TenantCustomHeaders } from '../util/constants'
const UNAUTHENTICATED = 'UNAUTHENTICATED'
const typeDefs = gql`
  type Filters {
    distance: Float
    distanceUnit: DistanceUnit
    latitude: Float
    longitude: Float
    maxPrice: Float
    minPrice: Float
    duration: Int
    makesModels: [MakeModel]
    bodyStyles: [String]
    fuelTypes: [String]
    transmission: [String]
    availableNow: Boolean
    dealers: [ID]
  }
  type MakeModel {
    make: String
    model: String
  }
  type User {
    firstName: String
    lastName: String
    lastName2: String
    token: String
    isLoggedIn: Boolean
  }
  extend type Query {
    filters: Filters
    user: User
  }
`

const credentialsSameOrigin = 'same-origin'
const headerVersion = '0.2'

export async function createGraphQLClient(mobileServiceUrl, setToken) {
  let timesLogout = 1
  const appId = await getAppId()
  const cache = new InMemoryCache()
  const uploadLink = createUploadLink({
    uri: mobileServiceUrl,
    credentials: credentialsSameOrigin,
    headers: {
      'tenant-id': settings.marketplaceId[appId],
      'app-bundle-id': appId,
      [TenantCustomHeaders.APP]: getPlatform(),
    },
  })
  const authLink = setContext((_, { headers: existingHeaders }) => {
    const token = loadToken()
    const headers = {
      ...existingHeaders,
      version: headerVersion,
    }
    if (token) {
      headers.authorization = token
    }
    return {
      headers,
    }
  })
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphqlError) => {
        const { message, locations, path } = graphqlError
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
        if (
          graphqlError.extensions.code === UNAUTHENTICATED &&
          timesLogout === 1
        ) {
          setToken(null)
          client.resetStore()
          refreshVehicleFilterCache(client)
          // setLogoutSubscriber(true)
          timesLogout++
        }
      })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })
  // TODO: Consider porting forward other links
  const client = new ApolloClient({
    cache,
    link: from([authLink, errorLink, uploadLink]),
    typeDefs,
    resolvers: {
      Mutation: {
        localFilters: async (parent, localFilterArgs) => {
          const updates = omitBy(localFilterArgs, isUndefined)
          const localFilter = client.readQuery({
            query: LOCAL_FILTER_QUERY,
          })
          const newFilterData = {
            filters: { ...(localFilter || {}).filters, ...updates },
          }
          client.writeQuery({
            query: LOCAL_FILTER_QUERY,
            data: newFilterData,
          })
          cacheVehicleFilters(newFilterData)
        },
      },
    },
  })
  return client
}

import classNames from 'classnames'
import FragmentOpacityTransition from '../FragmentOpacityTransition/FragmentOpacityTransition'
import { useEffect } from 'react'
import { useEventPublisher } from 'use-event-emitter-hook'
import { eventKeys } from '../../util/constants'

const classNameOverlay = 'fixed z-10 inset-0 bg-surface-overlay bg-opacity-50'

export default function OverlayDiv({ children, className, show }) {
  const { modalEventKey } = eventKeys
  let publisher = useEventPublisher()
  useEffect(() => {
    publisher(modalEventKey, show)

    return () => {
      publisher(modalEventKey, false)
    }
  }, [show])
  return (
    <FragmentOpacityTransition show={show}>
      <div className={classNames(className, classNameOverlay)}>{children}</div>
    </FragmentOpacityTransition>
  )
}

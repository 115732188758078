import { useEffect } from 'react'
import settings from '../settings'
import { isEmptyJSON } from './generalValidations'

const iStudioSalesforceId = 'i-studio-sf'
const iStudioSalesforceClass = 'optanon-category-C0003'
const htmlTagScript = 'script'
const typeJavascript = 'text/javascript'

export function InteractionStudioSalesforce() {
  useEffect(() => {
    addInteractionStudioScript()
  }, [])
  return null
}

const addInteractionStudioScript = () => {
  const interactionStudioSrc = settings.interactionStudioSalesforce

  if (
    interactionStudioSrc &&
    !isEmptyJSON(interactionStudioSrc) &&
    !scriptExistsInHTML(iStudioSalesforceId)
  ) {
    document.head.appendChild(
      getScriptTag(
        interactionStudioSrc,
        iStudioSalesforceId,
        iStudioSalesforceClass
      )
    )
  }
}
const getScriptTag = (src, sid, sclass) => {
  const scriptBlock = document.createElement(htmlTagScript)
  scriptBlock.id = sid
  scriptBlock.class = sclass
  scriptBlock.type = typeJavascript
  scriptBlock.src = src

  return scriptBlock
}

const scriptExistsInHTML = (elementId) => {
  const scriptElement = document.getElementById(elementId)

  return scriptElement ? true : false
}

import { STRING_BOOLEAN } from './constants'

const MILLISECONDS_IN_A_SECOND = 1000
const SECONDS_IN_A_MINUTE = 60
const MINUTES_IN_AN_HOUR = 60
const CENTS_IN_A_UNIT = 100

export const secondsToMilliseconds = (seconds = 0) => {
  return seconds * MILLISECONDS_IN_A_SECOND
}

export const millisecondsToHours = (milliseconds = 0) => {
  const millisecondsInAnHour =
    MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR

  const hours = parseInt(milliseconds / millisecondsInAnHour)

  return hours
}

export function centsToUnit(cents) {
  const unitAmount = cents / CENTS_IN_A_UNIT

  return unitAmount
}

export function unitToCents(unitAmount) {
  const cents = unitAmount * CENTS_IN_A_UNIT

  return cents
}

export function parseBoolean(value) {
  let booleanValue = undefined

  if (value === STRING_BOOLEAN.true) {
    booleanValue = true
  } else if (value === STRING_BOOLEAN.false) {
    booleanValue = false
  }

  return booleanValue
}

import { Currency } from 'f1-utils'

const { setParticularLocaleMethods } = Currency

export const currencyMethods = {}

export function loadCurrencyConfig(currencyConfig) {
  const localCurrencyMethods = setParticularLocaleMethods(currencyConfig)

  Object.assign(currencyMethods, localCurrencyMethods)
}

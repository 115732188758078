import { useEffect, useState } from 'react'

const isWidthMobile = () => window.innerWidth <= 1024

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(isWidthMobile())

  useEffect(() => {
    const onResize = () => {
      setIsMobile(isWidthMobile())
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}

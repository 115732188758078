import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { forwardRef } from 'react'
import { BUTTON_COLOR_TYPES } from '../../util/constants'

const DefaultButton = forwardRef((props, ref) => {
  return <button type="button" {...props} ref={ref} />
})

const LinkButton = forwardRef((props, ref) => {
  return <Link {...props} ref={ref} />
})

const Button = forwardRef(
  (
    { dropdown, to, className, color, size, rounded = 'rounded-lg', ...props },
    ref
  ) => {
    let Component = DefaultButton

    if (to) {
      Component = LinkButton
    }

    let sizeClasses = ''
    let colorClasses =
      'bg-primary text-white active:bg-primary-active disabled:bg-primary-disabled'
    let cursorClass = 'cursor-pointer'

    switch (size) {
      case 'large':
        sizeClasses = 'px-10 py-3'
        break
      case 'dropdown':
        sizeClasses = 'px-3 pr-2  py-2 w-full'
        break
      case 'medium':
        sizeClasses = 'px-3 py-2 w-full'
        break
      default:
        sizeClasses = 'px-10 py-2'
    }

    switch (color) {
      case BUTTON_COLOR_TYPES.primaryLight:
        colorClasses = 'bg-primary-light text-primary text-center'
        break
      case BUTTON_COLOR_TYPES.disabled:
        colorClasses =
          'bg-secondary text-white active:bg-secondary-active disabled:bg-secondary-active'
        break
      case BUTTON_COLOR_TYPES.secondary:
        colorClasses =
          'bg-secondary text-white text-center disabled:bg-secondary-disabled'
        break
      case BUTTON_COLOR_TYPES.transparent:
        colorClasses =
          'bg-transparent text-secondary text-center border border-secondary'
        break
      case BUTTON_COLOR_TYPES.error:
        colorClasses = 'bg-red-error text-white text-center'
        break
      case BUTTON_COLOR_TYPES.dropdown:
        colorClasses =
          'bg-transparent text-gray text-left border border-gray-light'
        break
      case BUTTON_COLOR_TYPES.gray_light_2:
        colorClasses =
          'bg-gray-light-2 text-gray text-left border border-gray-light-2'
        break
      case BUTTON_COLOR_TYPES.primaryReverse:
        colorClasses =
          'bg-transparent text-primary bg-white text-center border border-primary'
        break
      default:
        break
    }

    if (props.disabled) {
      cursorClass = 'cursor-not-allowed'
    }

    return (
      <Component
        className={classNames(
          className,
          `inline-block ${rounded} ${colorClasses} ${sizeClasses} ${cursorClass} focus:outline-none`
        )}
        ref={ref}
        to={to}
        {...props}
      />
    )
  }
)

export default Button

Button.propTypes = {
  color: PropTypes.oneOf([
    BUTTON_COLOR_TYPES.primary,
    BUTTON_COLOR_TYPES.primaryLight,
    BUTTON_COLOR_TYPES.secondary,
    BUTTON_COLOR_TYPES.transparent,
    BUTTON_COLOR_TYPES.error,
    BUTTON_COLOR_TYPES.dropdown,
    BUTTON_COLOR_TYPES.gray_light_2,
  ]),
  size: PropTypes.oneOf(['medium', 'large', 'dropdown']),
}

import OnboardingCarouselItem from './OnboardingCarouselItem'
import PropTypes from 'prop-types'
import { createRef, useEffect } from 'react'

export default function OnboardingCarouselWrapper(props) {
  const { carouselItemsList, closeCarousel, currentPage } = props
  const wrapperRef = createRef()
  const windowWidth = window.innerWidth

  useEffect(() => {
    if (!wrapperRef.current) {
      return
    }
    
    wrapperRef.current.scrollLeft = currentPage > 0 ? currentPage * windowWidth : 0
  }, [currentPage])

  return (
    <div className="flex overflow-hidden h-full" ref={wrapperRef}>
      {carouselItemsList.map((item, index) => {
        return (
          <OnboardingCarouselItem
            key={String(`carousel_item_${index}`)}
            shortTitle={item.shortTitle}
            title={item.title}
            closeCarousel={closeCarousel}
            description={item.description}
            backgroundColor={item.backgroundColor}
            backgroundImage={item.backgroundImage}
            index={index}
            total={carouselItemsList.length}
            currentPage={currentPage}
          />
        )
      })}
    </div>
  )
}

OnboardingCarouselWrapper.propTypes = {
  carouselItemsList: PropTypes.array,
  closeCarousel: PropTypes.func,
  wrapperRef: PropTypes.any,
  currentPage: PropTypes.number
}

import PropTypes from 'prop-types'
import BucketImg from '../../../components/BucketAsset/BucketImg'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'react-i18next'
import {
  BUTTON_COLOR_TYPES,
  CLOSE_CAROUSEL_OPTIONS,
  BULLET_POSITION,
} from '../../../util/constants'

export default function OnboardingCarouselItem(props) {
  const {
    shortTitle,
    title,
    description,
    backgroundColor,
    index,
    total,
    closeCarousel,
    backgroundImage,
  } = props
  const canShowItem = (text) => {
    return text?.length > 0
  }
  const canShowStartbutton = () => {
    return total === index + 1
  }
  const { t: carouselTranslation } = useTranslation('carousel')

  return (
    <div
      className={`flex h-full flex-col w-full flex-shrink-0 ${backgroundColor} p-6 pt-20 relative justify-between`}
    >
      {backgroundImage?.length > 0 && (
        <BucketImg
          src={backgroundImage}
          className="absolute left-0 top-0 w-full h-full object-fill"
        />
      )}
      <div className="z-10">
        {canShowItem(carouselTranslation(shortTitle)) && (
          <p className="text-white h-10 text-sm font-medium pb-4">
            {carouselTranslation(shortTitle)}
          </p>
        )}
        {canShowItem(carouselTranslation(title)) && (
          <p className="text-white text-3xl font-normal pb-4">
            {carouselTranslation(title)}
          </p>
        )}
        {canShowItem(carouselTranslation(description)) && (
          <p className="text-white text-lg font-normal">
            {carouselTranslation(description)}
          </p>
        )}
        {title.includes(BULLET_POSITION) &&
          canShowItem(carouselTranslation('carouselDescriptionBullets02')) && (
            <ul className="text-white text-lg font-normal list-carousel">
              {carouselTranslation('carouselDescriptionBullets02')
                .split(',')
                .map((el) => (
                  <li key={el}>{el}</li>
                ))}
            </ul>
          )}
      </div>
      <div className="z-10 w-full text-right pb-9">
        {canShowStartbutton() && (
          <Button
            size="large"
            rounded="rounded-xl"
            color={BUTTON_COLOR_TYPES.primary}
            onClick={() => closeCarousel(CLOSE_CAROUSEL_OPTIONS.fromCta)}
          >
            {carouselTranslation('carouselStartButton')}
          </Button>
        )}
      </div>
    </div>
  )
}

OnboardingCarouselItem.propTypes = {
  shortTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
  closeCarousel: PropTypes.func,
}

import axios from 'axios'
import { useEffect } from 'react'
import useAppState from '../hooks/useAppState'
import useWhiteLabels from '../hooks/useWhiteLabels'
import { cacheOrFetchRequest } from '../util/fetchRequestWithCache'

import {
  VALUES,
  FAVICON,
  VERSION,
  MANIFEST,
  CACHE_KEYS,
  QUERY_PARAM_NAMES,
  BUCKET_STYLE_SHEET_ID,
  PORTAL_STYLE_SHEET_ID
} from '../util/constants'
import { localStorageByCountry } from '../util/storageByCountry'
import {
  appendIconOverrideStyleSheet,
  appendStylesOverrideStyleSheet,
} from '../util/cssStyleHelper'
import { millisecondsToHours } from '../util/generalOperations'

const fontCSSVariable = '--font'
const displayMode = 'swap'

const setCachedManifestVersion = (key, manifestVersion) => {
  localStorageByCountry.setItem(key, manifestVersion)
}

const getCachedManifestVersion = (key) => {
  const manifestVersion = localStorageByCountry.getItem(key)

  return manifestVersion
}

const clearCachedRequests = () => {
  localStorageByCountry.removeItem(CACHE_KEYS.storedRequests)
}

export const fetchManifest = async (url) => {
  const timestamp = new Date().valueOf()
  const timestampInHours = millisecondsToHours(timestamp)

  const manifestUrl = `${url}${VALUES.slash}${MANIFEST}?${QUERY_PARAM_NAMES.hourstamp}=${timestampInHours}`
  let manifestResponse

  try {
    manifestResponse = await axios(manifestUrl)
  } catch (error) {
    console.error(error)

    return undefined
  }

  return manifestResponse?.data
}

export const handleManifestVersion = (
  manifestCacheKey,
  currentManifestVersion
) => {
  const cacheVersion = getCachedManifestVersion(manifestCacheKey)

  if (currentManifestVersion && cacheVersion !== currentManifestVersion) {
    clearCachedRequests()
    setCachedManifestVersion(manifestCacheKey, currentManifestVersion)
  }
}

export const getAssets = async (manifestData, url) => {
  let data = {}
  const manifestVersion = manifestData?.version

  if (url && manifestVersion) {
    for (let asset in manifestData) {
      if (asset !== VERSION && asset !== FAVICON) {
        const assetUrl = `${url}${manifestData[asset]}?${QUERY_PARAM_NAMES.assetVersion}=${manifestVersion}`

        try {
          const assetValue = await cacheOrFetchRequest(assetUrl)
          data[asset] = assetValue
        } catch {}
      }
      if (asset === FAVICON) data[asset] = manifestData[asset]
    }
  }

  return data
}

const updateFavicon = (url) => {
  let link = document.getElementById('favicon')
  link.href = url
}

const updateFont = async (font, fontFamily, bucketUrl) => {
  const newFont = new FontFace(fontFamily, `url('${bucketUrl}${font.url}')`, {
    display: displayMode,
  })

  try {
    const loadedFont = await newFont.load()

    loadedFont.weight = font.fontWeight
    document.fonts.add(loadedFont)
  } catch (error) {
    console.log('Failed to load font: ' + error)
  }
}

const updateFonts = (theme, bucketUrl) => {
  theme.fonts.forEach((el) => {
    updateFont(el, theme.fontFamily, bucketUrl)
  })
  document.documentElement.style.setProperty(fontCSSVariable, theme.fontFamily)
}

export const AssetLoader = () => {
  const { loadAssets, assets, fallbackAssets } = useAppState()
  const whitelabels = useWhiteLabels()

  useEffect(() => {
    if (whitelabels && whitelabels.whitelabelsUrl) {
      loadAssets(whitelabels.whitelabelsUrl)
    }
  }, [whitelabels])

  useEffect(() => {
    if (assets?.colors) {
      for (let color of assets.colors.colors)
        document.documentElement.style.setProperty(color.name, color.value)
      appendIconOverrideStyleSheet(whitelabels.whitelabelsUrl, assets.icons)
      updateFavicon(whitelabels.whitelabelsUrl + assets.favicon)
    } else if (fallbackAssets) {
      for (let color of fallbackAssets.colors.colors)
        document.documentElement.style.setProperty(color.name, color.value)
      appendIconOverrideStyleSheet(process.env.PUBLIC_URL, fallbackAssets.icons)
      updateFavicon(process.env.PUBLIC_URL + fallbackAssets.favicon)
    }
    if (assets?.colorsNew && Array.isArray(assets.colorsNew.colors)) {
      for (let color of assets.colorsNew.colors)
        document.documentElement.style.setProperty(color.name, color.value)
    }
  }, [assets, fallbackAssets])

  useEffect(() => {
    if (assets?.theme?.fonts) {
      updateFonts(assets.theme, whitelabels.whitelabelsUrl)
    }
    if (assets?.styles) {
      appendStylesOverrideStyleSheet(assets.styles, BUCKET_STYLE_SHEET_ID)
    }
    if (assets?.stylesNew) {
      appendStylesOverrideStyleSheet(assets.stylesNew, PORTAL_STYLE_SHEET_ID)
    }
  }, [assets])

  return null
}

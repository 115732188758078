import { useEffect, useState } from 'react'
import BucketSvgIcon from '../../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import PropTypes from 'prop-types'
import { EMPTY_STRING } from '../../../util/constants'
import { omitNotch } from '../../../util/helper'

export default function OnboardingCarouselControls(props) {
  const { goToPage, carouselItemsList, closeCarousel } = props

  const [currentProgress, setCurrentProgress] = useState(0)
  const [intervalid, setIntervalId] = useState()
  const [currentProgressPage, setCurrentProgressPage] = useState(0)
  const progressBarMaxValue = 100
  const progressBarUpdateInterval = 100

  const changeSlide = (index) => {
    setCurrentProgress(0)
    goToPage(index)
    setCurrentProgressPage(index)
  }

  const triggerPreviousPage = () => {
    if (currentProgressPage === 0) {
      return
    }
    changeSlide(currentProgressPage - 1)
  }

  const triggerNextPage = () => {
    if (currentProgressPage === carouselItemsList.length) {
      return
    }
    changeSlide(currentProgressPage + 1)
  }

  const getProgressBarWidth = (index) => {
    if (currentProgressPage === index) {
      return `${currentProgress}%`
    }
    return index < currentProgressPage ? '100%' : '0%'
  }

  const updateProgressBar = () => {
    const timer = setInterval(() => {
      setCurrentProgress((currentProgress) => {
        if (currentProgress === progressBarMaxValue) {
          return 0
        }
        return currentProgress + 1
      })
    }, progressBarUpdateInterval)
    setIntervalId(timer)
  }

  useEffect(() => {
    updateProgressBar()

    return () => clearInterval(intervalid)
  }, [])

  const nextStep = () => {
    setTimeout(() => {
      if (currentProgressPage === carouselItemsList.length) {
        clearInterval(intervalid)
      } else {
        setCurrentProgressPage(
          (currentProgressValue) => currentProgressValue + 1
        )
        goToPage(currentProgressPage + 1)
      }
    }, progressBarUpdateInterval)
  }

  useEffect(() => {
    if (currentProgress === progressBarMaxValue) {
      nextStep()
    }
  }, [currentProgress])

  return (
    <>
      <div
        className={`h-20 absolute w-full  ${omitNotch ? 'pt-6' : EMPTY_STRING}`}
      >
        <div className="flex flex-col mt-4 items-end px-4 z-10 relative">
          <BucketSvgIcon
            onClick={closeCarousel}
            src="x-icon"
            className="text-white"
          />
          <div className="flex flex-row w-full">
            {carouselItemsList.map((item, index) => {
              return (
                <div
                  key={String(`carousel_control_${index}`)}
                  onClick={() => changeSlide(index)}
                  className="flex flex-1 mx-1 py-2 flex-col"
                >
                  <div
                    className="bg-white opacity-30 w-full"
                    style={{ height: '2px' }}
                  ></div>

                  <div
                    className={`bg-white opacity-70 w-0 relative ${
                      currentProgressPage > index && 'opacity-100'
                    }`}
                    style={{
                      width: getProgressBarWidth(index),
                      height: '2px',
                      top: '-2px',
                    }}
                  ></div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="absolute w-full bottom-24 top-24 flex flex-row z-50">
        <div
          className="h-full flex flex-1  z-50  z-50 relative"
          onClick={triggerPreviousPage}
        ></div>
        <div
          className="h-full flex flex-1  z-50  z-50 relative"
          onClick={triggerNextPage}
        ></div>
      </div>
    </>
  )
}

OnboardingCarouselControls.propTypes = {
  goToPage: PropTypes.func,
  carouselItemsList: PropTypes.array,
  closeCarousel: PropTypes.func,
}

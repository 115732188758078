import { PATH_SEPARATOR, PDF_EXTENSION, TOOLBAR_POSTFIX } from './constants'

export const countryIndexInPath = 1
export const languageIndexInPath = 2

const emptyPath = ''

export const getCountryFromPath = () => {
  const currentPath = window.location.pathname || emptyPath
  const splitPath = currentPath.split(PATH_SEPARATOR)
  const country = splitPath[countryIndexInPath]

  return country
}

export const getLanguageFromPath = () => {
  const currentPath = window.location.pathname || emptyPath
  const splitPath = currentPath.split(PATH_SEPARATOR)
  const language = splitPath[languageIndexInPath]

  return language
}

export const addParametersToUrl = (url, parameters = []) => {
  let urlWithParameters

  try {
    const urlObject = new URL(url)

    parameters.forEach((parameter) => {
      urlObject.searchParams.append(parameter.name, parameter.value)
    })

    urlWithParameters = urlObject.href
  } catch {
    urlWithParameters = url
  }

  return urlWithParameters
}

export const getSearchParamValue = (urlSearchParams, parameterName) => {
  const parameterValue = urlSearchParams.get(parameterName)

  return parameterValue
}

export const getSearchFromUrl = (url) => {
  const urlObject = new URL(url)
  const urlSearch = urlObject.search

  return urlSearch
}

export const setQueryParams = (urlSearchParams, parametersConfig = []) => {
  parametersConfig.forEach((parameterConfig) => {
    const { name, value } = parameterConfig

    urlSearchParams.set(name, value)
  })
}

export const isPdfFile = (urlOrDoc) =>
  urlOrDoc && urlOrDoc.includes(PDF_EXTENSION)
export const getFormattedUrl = (urlDocument) =>
  isPdfFile(urlDocument) ? `${urlDocument}${TOOLBAR_POSTFIX}` : urlDocument

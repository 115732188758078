const {
  PAGE_EVENTS,
  PORTAL_STYLE_SHEET_ID,
  EMPTY_STRING,
} = require('../util/constants')
const { appendStylesOverrideStyleSheet } = require('../util/cssStyleHelper')

function isAllowedUrl(allowedUrls, origin) {
  if (!allowedUrls) return false

  return allowedUrls.some((url) => origin.includes(url))
}

function handleStyleMessageEvent(event, allowedUrls) {
  const { origin, data } = event
  const { message } = data

  if (!message || !isAllowedUrl(allowedUrls, origin)) return

  const { styles, colors } = message

  if (styles) {
    var oldStyle = document.getElementById(PORTAL_STYLE_SHEET_ID)

    if (oldStyle) oldStyle.remove()

    appendStylesOverrideStyleSheet(styles, PORTAL_STYLE_SHEET_ID)
  }

  if (colors && colors !== EMPTY_STRING) {
    const parsedColors = JSON.parse(colors)

    if (parsedColors?.colors) {
      for (let color of parsedColors.colors) {
        document.documentElement.style.setProperty(color.name, color.value)
      }
    }
  }
}

export default function addStyleMessageListener(allowedUrls) {
  window.addEventListener(PAGE_EVENTS.message, (event) => {
    handleStyleMessageEvent(event, allowedUrls)
  })
}

import { gql, useQuery } from '@apollo/client'
import useAppState from '../../../hooks/useAppState'

export const CURRENT_SUBSCRIBER_QUERY = gql`
  {
    currentSubscriber {
      id
      billingHistory {
        billResults {
          totalCount
          pageInfo {
            hasNextPage
          }
          bills {
            id
            date
            status
            amount
            billReferenceNumber
          }
        }
      }
    }
  }
`

export default function useBillingHistory() {
  const { isLoggedIn } = useAppState()
  return useQuery(CURRENT_SUBSCRIBER_QUERY, {
    skip: !isLoggedIn,
  })
}

import { useEffect } from 'react'
import { isNativePlatform } from '../util/native/nativeUtils'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { FIREBASE_PERMISSIONS } from '../util/constants'
import {
  TOKEN_REGISTRATION_MUTATION,
  registerDeviceToken,
} from '../hooks/useDeviceToken'
import useAppState from '../hooks/useAppState'
import { useMutation } from '@apollo/client'


const addNotificationReceivedListener = async () => {
  if (!isNativePlatform()) return
  await FirebaseMessaging.addListener('notificationReceived', () => {})
}

const setupPermissions = async () => {
  if (!isNativePlatform()) return
  try {
    const { receive } = await FirebaseMessaging.requestPermissions()
    const disabled = receive === FIREBASE_PERMISSIONS.denied
    if (!disabled) return receive
    throw receive
  } catch (error) {
    throw error
  }
}

export const setupFirebaseMessagingToken = async () => {
  if (!isNativePlatform()) return
  try {
    await setupPermissions()
    await addNotificationReceivedListener()
  } catch (err) {
    console.error(err)
  }
}

const removeListeners = () => {
  if (!isNativePlatform()) return
  FirebaseMessaging.removeAllListeners()
}

const registerDevice = (isLoggedIn, registerDeviceTokenMutation) => {
  if (!isNativePlatform() || !isLoggedIn) return
  registerDeviceToken(registerDeviceTokenMutation)
}

export const FirebaseMessagingProvider = () => {
  const { isLoggedIn } = useAppState()
  const [registerDeviceTokenMutation] = useMutation(TOKEN_REGISTRATION_MUTATION)

  useEffect(() => {
    setupFirebaseMessagingToken()
    return () => {
      removeListeners()
    }
  }, [])

  useEffect(() => {
    registerDevice(isLoggedIn, registerDeviceTokenMutation)
  }, [isLoggedIn])

  return <></>
}

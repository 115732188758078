import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { FAVOURITES_KEY, saveVehicleSessionStorage } from './useFavourites'
import useAppState from './useAppState'

const FAVOURITE_VEHICLE_SAVE_FILTER = gql`
  mutation saveFavoriteVehicles($input: SaveFavoriteVehiclesInput!) {
    saveFavoriteVehicles(input: $input) {
      ok
    }
  }
`

export function useSaveFavouritesVehicleMutation() {
  const [saveToFavouritesAction] = useMutation(FAVOURITE_VEHICLE_SAVE_FILTER)

  const saveToFavourites = useCallback((vehicleIds) => {
    return saveToFavouritesAction({
      variables: {
        input: { vehicleIds },
      },
    }).then((response) => {
      window.sessionStorage.removeItem(FAVOURITES_KEY)
      return response
    })
  })

  return { saveToFavourites }
}

export default function useSaveFavouriteVehicleMutation() {
  const { isLoggedIn } = useAppState()
  const [saveToFavouritesAction] = useMutation(FAVOURITE_VEHICLE_SAVE_FILTER)

  const saveToFavourites = useCallback((vehicleIds) => {
    return saveToFavouritesAction({
      variables: {
        input: { vehicleIds: [vehicleIds] },
      },
    })
  })

  if (!isLoggedIn) {
    return {
      saveToFavourites: saveVehicleSessionStorage,
    }
  }

  return { saveToFavourites }
}

import { gql } from '@apollo/client'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { isNativePlatform } from '../util/native/nativeUtils'

export const TOKEN_REGISTRATION_MUTATION = gql`
  mutation RegisterToken($input: RegisterDeviceTokenInput!) {
    registerDeviceToken(input: $input) {
      ok
    }
  }
`

export const TOKEN_UNREGISTRATION_MUTATION = gql`
  mutation UnregisterToken($input: UnregisterDeviceTokenInput!) {
    unregisterDeviceToken(input: $input) {
      ok
    }
  }
`
export const registerDeviceToken = async (registerDeviceTokenMutation) => {
  if (!isNativePlatform()) return
  const { token } = await FirebaseMessaging.getToken()
  return registerDeviceTokenMutation({
    variables: { input: { token } },
  })
}
export const unregisterDeviceToken = async (unregisterDeviceTokenMutation) => {
  if (!isNativePlatform()) return
  const { token } = await FirebaseMessaging.getToken()
  return unregisterDeviceTokenMutation({
    variables: { input: { token } },
  })
}

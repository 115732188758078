import useCustomNavigate from '../../hooks/useCustomNavigate'
import { setShowOnboardingCarousel } from './helpers'
import { useEffect, useState } from 'react'
import OnboardingCarouselControls from './components/OnboardingCarouselControls'
import OnboardingCarouselWrapper from './components/OnboardingCarouselWrapper'
import settings from '../../settings'
import { INTERNAL_ROUTES } from '../../util/internalRoutes'
import {
  CLOSE_CAROUSEL_OPTIONS,
  LENGTH_ONE_ELEMENT,
  VALUE_ZERO,
} from '../../util/constants'
import {
  tagManagerSwipeCarousel,
  tagManagerSwipeCarouselClose,
} from '../../tracking/trackingCarouselPage'
import useSubscriber from '../../hooks/useSubscriber'
import useBillingHistory from '../BillsPage/components/useBillingHistory'

export default function OnboardingCarrousel() {
  const navigate = useCustomNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const { data: subscriberData } = useSubscriber()
  const { data: dataBillingHistory } = useBillingHistory()
  const bills =
    dataBillingHistory?.currentSubscriber?.billingHistory?.billResults?.bills

  const carouselItemsList = settings.carouselSlides

  const closeCarousel = (type = CLOSE_CAROUSEL_OPTIONS.default) => {
    setShowOnboardingCarousel()
    navigate(INTERNAL_ROUTES.SRP)

    if (type === CLOSE_CAROUSEL_OPTIONS.fromCta) {
      tagManagerSwipeCarouselClose(subscriberData, bills)
      return
    }

    tagManagerSwipeCarouselClose(subscriberData, bills)
  }

  useEffect(() => {
    if (carouselItemsList?.length && currentPage < carouselItemsList.length) {
      tagManagerSwipeCarousel(currentPage + 1, subscriberData, bills)
    }
  }, [currentPage])

  if (!carouselItemsList || carouselItemsList.length < LENGTH_ONE_ELEMENT) {
    closeCarousel()
    return <></>
  }

  const goToPage = (index) => {
    if (
      currentPage === VALUE_ZERO &&
      currentPage === carouselItemsList.length
    ) {
      return
    }
    setCurrentPage(index)
  }

  return (
    <div className="w-full relative h-screen">
      <OnboardingCarouselControls
        goToPage={goToPage}
        closeCarousel={closeCarousel}
        carouselItemsList={carouselItemsList}
        currentPage={currentPage}
      />
      <OnboardingCarouselWrapper
        carouselItemsList={carouselItemsList}
        closeCarousel={closeCarousel}
        currentPage={currentPage}
      />
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button/Button'
import { BUTTON_COLOR_TYPES, TRANSLATIONS } from '../util/constants'
import BucketSvgIcon from '../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import BucketImg from '../components/BucketAsset/BucketImg'
import { omitNotch } from '../util/helper'
import useNativeDownloadApp from '../hooks/useNativeDownloadApp'

const UpdatePopup = ({updateIsMandatory, setOpen}) => {
  const { t } = useTranslation(TRANSLATIONS.UI)
  const { onClickNativeDownload } = useNativeDownloadApp()

  return (
    <div className="flex flex-1 flex-col w-screen h-screen bg-primary justify-between items-center p-6">
      {!updateIsMandatory ? (
        <div className={`block absolute ${omitNotch ? 'pt-6' : 'pt-0'} right-0 pt-2 pr-2 sm:pt-4 sm:pr-4`}>
          <button
            type="button"
            className="modal-close-button text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={() => setOpen(false)}
          >
            <span className="sr-only">Close</span>
            <BucketSvgIcon
              src="x-icon"
              aria-hidden="true"
            />
          </button>
        </div>
      ) : null}
      
      <div className="flex flex-1 flex-col justify-end">
        <p className="text-white text-4xl text-center font-light m-auto mt-20">
          {t('appUpdateRequired')}
        </p>
        <p className="text-white text-base text-center m-auto">
          {t('appOutdated')}
        </p>
        <BucketImg
          id={'update'}
          src={`update`}
          alt={'update'}
          className="m-auto"
        />
        <Button
          size="large"
          rounded="rounded-xl"
          className="w-full mt-auto"
          color={BUTTON_COLOR_TYPES.primaryReverse}
          onClick={onClickNativeDownload}
        >
          <p >{t('pleaseUpdate')}</p>
        </Button>
      </div>
      
    </div>
  )
}

export default UpdatePopup

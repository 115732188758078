import TextClickable from '../components/TextClickable/TextClickable'
import settings from '../settings'
import { CHECKS, SPAN_PREFIX } from './constants'
import { isAValidURL } from './generalValidations'
import { navigateToExternalLink } from './navigateToExternalLink'
import { translateTextWithLinks, getFileUrl } from './translationsHelper'
import { INTERNAL_ROUTES } from '../util/internalRoutes'

const getCheckElement = (checkInner, urlTranslation, documentsUrls) => {
  if (checkInner.type === CHECKS.LABEL_TYPE_TEXT) return checkInner.text
  else if (checkInner.type === CHECKS.LABEL_TYPE_LINK)
    return (
      <TextClickable
        text={checkInner.text}
        key={checkInner.url}
        underline
        onClick={(event) =>
          onLinkClick(event, checkInner.url, urlTranslation, documentsUrls)
        }
      />
    )

  return null
}

const onLinkClick = (event, link, urlTranslation, documentsUrls) => {
  event.preventDefault()

  const linkUrl = isAValidURL(link)
    ? link
    : urlTranslation(getFileUrl(link, documentsUrls))
  const openNewTab = true
  navigateToExternalLink(linkUrl, openNewTab)
}

export const getCheckLabel = (
  key,
  checkTranslation,
  urlTranslation,
  documentsUrls
) => {
  const checkText = checkTranslation(key)
  const checkData = translateTextWithLinks(checkText, documentsUrls)

  return checkData.map((checkRow) => (
    <p key={`${SPAN_PREFIX}${key}`}>
      {checkRow.map((checkInner) =>
        getCheckElement(checkInner, urlTranslation, documentsUrls)
      )}
    </p>
  ))
}

export const handleCheckChange = (
  index,
  agree,
  registrationChecksItems,
  setRegistrationChecksItems
) => {
  const newRegistrationChecksItems = [...registrationChecksItems]
  newRegistrationChecksItems[index].value = agree
  setRegistrationChecksItems(newRegistrationChecksItems)
}

export const getRegistrationChecksItems = () => {
  let checkItemsByColumn = []
  for (const key in settings?.registrationChecks.checks) {
    const item = {
      key,
      value: false,
    }
    checkItemsByColumn.push(item)
  }
  return checkItemsByColumn
}

export const acceptNewChecks = (navigate) => {
  navigate(INTERNAL_ROUTES.ACCEPT_NEW_REGISTER_CHECKS)
}

import { Spinner } from 'asb-frontend'
import OverlayDiv from '../OverlayDiv/OverlayDiv'

const spinnerOverlaySize = 72

export default function SpinnerOverlay({ show }) {
  return (
    <OverlayDiv
      show={show}
      className="flex w-full h-full items-center justify-center"
    >
      <Spinner size={spinnerOverlaySize} />
    </OverlayDiv>
  )
}

import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { isNativePlatform } from '../util/native/nativeUtils'
import { App } from '@capacitor/app'

const CHECK_APP_VERSION_QUERY = gql`
  query CheckAppVersion($versionNumber: String!) {
    checkAppVersion(versionNumber: $versionNumber) {
      updateRequired
      updateMandatory
    }
  }
`

export default function useAppNeedsUpdate() {
  const [appVersionCheck] = useLazyQuery(CHECK_APP_VERSION_QUERY)
  const [updateWarningVisible, setUpdateWarningVisible] = useState(false)
  const [updateIsMandatory, setUpdateMandatory] = useState(false)

  const dismissPopUp = () => {
    setUpdateWarningVisible(false)
  }

  useEffect(async () => {
    if (isNativePlatform()) {
      try {
        const appInfo = await App.getInfo()
        const versionNumber = appInfo.version
        const appVersionCheckResult = await appVersionCheck({ variables: { versionNumber } })
        if(appVersionCheckResult?.data?.checkAppVersion) {
          setUpdateWarningVisible(appVersionCheckResult.data.checkAppVersion.updateRequired)
          setUpdateMandatory(appVersionCheckResult.data.checkAppVersion.updateMandatory)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  return { updateWarningVisible, updateIsMandatory, dismissPopUp }
}

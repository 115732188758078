import settings from '../settings'
import i18n from './i18n'
import numeral from 'numeral'
import { VALUES } from '../util/constants'

const formatNames = {
  brandName: 'brandName',
  distanceUnit: 'distanceUnit',
  distanceUnitShort: 'distanceUnitShort',
}

const formatPatterns = {
  distance: '0,0.[0.0]',
}

const zeroDistance = 0
const kmSortUnit = 'km'

const format = (value, format) => {
  if (format === formatNames.brandName) {
    return settings.brandName
  }

  if (
    format === formatNames.distanceUnit ||
    format === formatNames.distanceUnitShort
  ) {
    let prefix =
      value || value === zeroDistance
        ? numeral(parseFloat(value)).format(formatPatterns.distance)
        : VALUES.empty

    if (format === formatNames.distanceUnitShort) {
      return `${prefix} ${kmSortUnit}`.trim()
    }
    if (value === 1) {
      return `${prefix} ${i18n.t('format:kilometer')}`.trim()
    }
    return `${prefix} ${i18n.t('format:kilometers')}`.trim()
  }
}

export { format }

import { VALUES } from './constants'
import { Buffer } from 'buffer'
import { currencyMethods } from './currencyHelper'
import { DateFormatHelper } from 'f1-utils'

export const {
  formatShortDate,
  formatMonthAndDay,
  formatYearMonthDay,
  formatMonth,
  formatDay,
  formatTime,
  formatDate,
} = DateFormatHelper

export function formatCurrencyInCents(cents, { excludeCents } = {}) {
  const formattedPrice = currencyMethods.format(cents, false, excludeCents)

  return formattedPrice
}

export function formatDistance(distance) {
  return Intl.NumberFormat('es-ES', {
    style: 'unit',
    unit: 'kilometer',
  }).format(distance)
}

export function formatNumber(number) {
  return Intl.NumberFormat('es-ES').format(number)
}

export function toUpperCaseFirstCharacter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
}

export function formatPrintCreditCardNumber(lastFour) {
  return `**** **** **** ${lastFour}`
}

export function formatPrintCreditCardExpiration(month, year) {
  return `${month}/${year % 100}`
}

const dataUrlSeparator = ','
//the regular expression finds mimetype pattern (anything between ":" and ";" characters): in "data:image/png;base64" the regular expression checks "image/png"
const dataUrlMimePattern = /:(.*?);/
const base64Text = 'base64'

export function dataURLtoFile(dataUrl, fileName) {
  const data = dataUrl.split(dataUrlSeparator)
  const mime = data[0].match(dataUrlMimePattern)[1]
  const u8arr = Buffer(data[1], base64Text)

  const file = new File([u8arr], fileName, {
    type: mime,
  })

  return file
}

export function lowercaseFirstLetter(string) {
  const firstLetterPosition = 0
  const secondLetterPosition = 0
  return (
    string.charAt(firstLetterPosition).toLowerCase() +
    string.slice(secondLetterPosition)
  )
}

export function removeSpaces(string) {
  return string.replace(spacesFormat, VALUES.empty)
}

export function capitalizeFirstLowercaseRest(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

//the regular expression checks a valid email pattern
export const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

//the regular expression allows: numbers, +, spaces, and - in telephone numbers
export const phoneFormat = /^([0-9 +-]+)$/

//the regular expression allows: letters and numbers
export const alphaNumericFormat = /[^0-9a-zA-Z]/

//the regular expression validate a correct google analytics client
export const matchGAClient = {
  match: /(?:(?:^|.*;\s*)_ga\s*=\s*([^;]*).*$)|^.*$/,
  patern: '$1',
}

//the regular expression checks: all the spaces
export const spacesFormat = /\s+/g

//the regular expression allows: two blocks of 4 numbers/letters separated by a dash (XXXX-XXXX)
export const verifyEmailCodeFormat = /^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/

//the regular expression allows: 4 numbers/letters  (XXXX)
export const verifyOTPCodeFormat = /^[0-9a-zA-Z]{4}$/

//the regular expression capture substrings whit the format [Link text](Link url) to create links
export const verifyTextWithLinksFormat = /\[([^[\]]*)\]\(([^()]*)\)/g

export const formatCityStateZip = (address) => {
  if (!address) return null

  const addressArray = []
  if (address.city) addressArray.push(address.city)
  if (address.state) addressArray.push(address.state)
  if (address.zip) addressArray.push(address.zip)

  const formattedAdderess = addressArray.join(', ')
  return formattedAdderess
}

const fiscalCodePattern = /^[A-Z]{6}\d{2}[A-Z]{1}\d{2}[A-Z]{1}\d{3}[A-Z]{1}$/

const fiscalCodeFormats = [
  {
    country: 'IT',
    pattern: fiscalCodePattern,
  },
  {
    country: 'FR',
    pattern: fiscalCodePattern,
  },
]

export const getFiscalCodePatternByCountry = (countryCode) => {
  const fiscalCodeFormat = fiscalCodeFormats?.find(
    (fiscalCodeFormatElement) =>
      fiscalCodeFormatElement?.country === countryCode
  )

  const fiscalCodePattern = fiscalCodeFormat?.pattern

  return fiscalCodePattern
}

const commonFormat = /^[A-zÀ-ú]*$/

const commonFormats = [
  {
    country: 'IT',
    pattern: commonFormat,
  },
  {
    country: 'FR',
    pattern: commonFormat,
  },
]

export const getCommonPatternByCountry = (countryCode) => {
  const commonFormat = commonFormats?.find(
    (commonFormat) => commonFormat?.country === countryCode
  )

  const commonPattern = commonFormat?.pattern

  return commonPattern
}

export const hoverLinkColor = 'hover:text-gray'

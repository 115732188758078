import React, { useRef, useEffect } from 'react'

function addClassNameToSvgString(svgString, className) {
  const svgKey = 'svg'
  const classNameString = `class="${className}"`
  const keyAndClassName = `${svgKey} ${classNameString}`

  const updatedSvgString = svgString.replace(svgKey, keyAndClassName)

  return updatedSvgString
}

export default function SvgFromString({ svgString, className, ...props }) {
  const divRef = useRef()

  useEffect(() => {
    if (divRef && svgString) {
      divRef.current.innerHTML = className
        ? addClassNameToSvgString(svgString, className)
        : svgString
    }
  }, [divRef, svgString, className])

  const SvgElement = <div ref={divRef} {...props}></div>

  return svgString ? SvgElement : null
}

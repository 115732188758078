import { HOURS_DAY, SETUP_DELAY_LIMIT, BALLOON } from './constants'

export default function getWhenAvailableText(
  setupDelay,
  translationFunction,
  vehicle
) {
  let text

  if (!setupDelay) {
    text = translationFunction('availableNow')

    let balloonText
    if (vehicle?.pricing[0].productType === BALLOON) {
      balloonText = translationFunction('prodTypeBallon')
    } else {
      balloonText = translationFunction('prodTypeSubscription')
    }

    if (balloonText) {
      text = balloonText
    }
  } else if (setupDelay <= SETUP_DELAY_LIMIT) {
    text = translationFunction('hoursWait', { hours: setupDelay * HOURS_DAY })
  } else {
    text = translationFunction('daysWait', { days: setupDelay })
  }

  return text
}

const TOKEN_KEY = 'TOKEN_KEY'

// Singleton token
let token = null

export function getToken() {
  return token
}

export function loadToken() {
  const storedToken =
    window.localStorage.getItem(TOKEN_KEY) ||
    window.sessionStorage.getItem(TOKEN_KEY)
  token = storedToken

  return token
}

export function deleteTokens() {
  window.localStorage.removeItem(TOKEN_KEY)
  window.sessionStorage.removeItem(TOKEN_KEY)
}

export function saveToken(newToken, rememberToken) {
  token = newToken

  deleteTokens()

  if (token && rememberToken) {
    window.localStorage.setItem(TOKEN_KEY, token)
  } else if (token) {
    window.sessionStorage.setItem(TOKEN_KEY, token)
  }
}

import _ from 'lodash'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as baseLocales from './locale'
import { format } from './format'
import LanguageDetector from 'i18next-browser-languagedetector'
import { VALUES, PATH_SEPARATOR } from '../util/constants'
import { LANGUAGE_CONFIG } from '../util/languagesManager'
import { getDefaultCountryCode } from '../util/countryHelper'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/nb'
import 'moment/locale/fr'
import 'moment/locale/de-ch'
import 'moment/locale/it'
import 'moment/locale/sv'
import 'moment/locale/pl'

const resources = Object.keys(baseLocales).reduce(
  (localeTranslations, localeKey) => {
    let localeKeyFormatted
    if (localeKey) localeKeyFormatted = localeKey.replace('_', '-')
    localeTranslations[localeKeyFormatted] = _.merge({}, baseLocales[localeKey])
    return localeTranslations
  },
  {}
)

const languageDetector = new LanguageDetector()

// Loads locale files
i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      format,
    },
    detection: {
      order: ['path', 'localStorage'],
      lookupFromPathIndex: 1,
    },
  })

i18n.options.fallbackLng = LANGUAGE_CONFIG.fallbackLanguage

moment.locale(i18n.language)

i18n.changeLanguageAndUrl = (locale, navigate) => {
  const urlSectionOffset = 3

  const splitPath = window.location.pathname.split(PATH_SEPARATOR)
  const [, pathCountryCode, pathLanguageCode] = splitPath

  const pathPageSection = splitPath.slice(urlSectionOffset).join(PATH_SEPARATOR)

  const newLocale = locale || pathLanguageCode
  const newCountryCode = pathCountryCode || getDefaultCountryCode()
  const newPageSection = pathPageSection || VALUES.empty
  const newPageSearchParams = window.location.search

  i18n.changeLanguage(newLocale)
  moment.locale(newLocale)

  navigate(
    `/${newCountryCode}/${newLocale}/${newPageSection}${newPageSearchParams}`,
    {
      replace: true,
      isFullUrl: true,
    }
  )
}

i18n.existsKey = (namespace, key) => {
  const fullKey = [namespace, key].join(VALUES.colon)

  return i18n.exists(fullKey)
}

export default i18n

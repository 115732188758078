import { de, es, enUS, fr, nb, it, sv } from 'date-fns/locale'
import { LanguageConfig } from 'f1-utils'

export const DATE_LOCALE_MAPPING = {
  en: enUS,
  es: es,
  fr: fr,
  de: de,
  'de-CH': de,
  no: nb,
  it: it,
  se: sv,
}

const fallbackLanguageKey = 1
const fallbackLanguageName = LanguageConfig.LANGUAGES_ENUM[fallbackLanguageKey]
const fallbackLanguageCode = LanguageConfig.I18_MAPPING[fallbackLanguageName]

export const LANGUAGE_CONFIG = {
  fallbackLanguage: fallbackLanguageCode,
  defaultLanguage: fallbackLanguageCode,
  availableLanguages: [],
}

export const updateLanguageConfig = (availableLanguages) => {
  const firstElementIndex = 0
  const defaultLanguage = availableLanguages[firstElementIndex]?.languageCode

  const newLanguageConfig = {
    defaultLanguage,
    availableLanguages,
  }

  Object.assign(LANGUAGE_CONFIG, newLanguageConfig)
}

export const updateLanguageInHTML = (languageCode) => {
  const languagePropertyName = 'lang'

  document.documentElement.setAttribute(languagePropertyName, languageCode)
}

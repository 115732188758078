import { gql, useMutation } from '@apollo/client'
import useAppState from './useAppState'
import { deleteVehicleSessionStorage } from './useFavourites'

const FAVOURITE_VEHICLE_DELETE_FILTER = gql`
  mutation removeFavoriteVehicle($input: RemoveFavoriteVehicleInput!) {
    removeFavoriteVehicle(input: $input) {
      ok
    }
  }
`

export default function useDeleteFavouriteVehicleMutation() {
  const { isLoggedIn } = useAppState()

  const [deleteToFavouritesAction] = useMutation(
    FAVOURITE_VEHICLE_DELETE_FILTER
  )

  const deleteFromFavourites = (vehicleId) => deleteToFavouritesAction({
    variables: {
      input: { vehicleId },
    },
  })

  if (!isLoggedIn) {
    return { deleteFromFavourites: deleteVehicleSessionStorage }
  }

  return { deleteFromFavourites }
}

import { gql, useQuery } from '@apollo/client'

export const WHITELABELS_QUERY = gql`
  query WhitelabelsUrl($manifestVersion: String) {
    whitelabelsUrl(manifestVersion: $manifestVersion) {
      whitelabelsUrl
    }
  }
`

export default function useWhiteLabels() {
  const { data } = useQuery(WHITELABELS_QUERY, {
    variables: {
      manifestVersion: null,
    },
  })

  return data?.whitelabelsUrl
}

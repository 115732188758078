import { useEffect, useState } from 'react'
import BucketSvgIcon from '../../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import useDeleteFavouriteVehicleMutation from '../../../hooks/useDeleteFavouriteVehicleMutation'
import useSaveFavouriteVehicleMutation from '../../../hooks/useSaveFavouriteVehicleMutation'
import classNames from 'classnames'

export default function VehicleFavourite({
  id,
  favouriteVehicleIds,
  trackFunction = () => {},
  disabled,
  className,
}) {
  const { saveToFavourites } = useSaveFavouriteVehicleMutation()
  const { deleteFromFavourites } = useDeleteFavouriteVehicleMutation()
  const [isFavourite, setIsFavourite] = useState(false)

  useEffect(() => {
    if (favouriteVehicleIds?.includes(id)) {
      setIsFavourite(true)
    }
  }, [favouriteVehicleIds])

  const switchFavourite = () => {
    const newIsFavourite = !isFavourite

    trackFunction(newIsFavourite)
    newIsFavourite ? saveToFavourites(id) : deleteFromFavourites(id)

    setIsFavourite(newIsFavourite)
  }

  let srcIconName

  if (disabled) {
    srcIconName = 'fi-rr-trash'
  } else if (isFavourite) {
    srcIconName = 'heart-red'
  } else {
    srcIconName = 'heart-outline'
  }

  return (
    <div
      className={classNames('cursor-pointer', className)}
      onClick={switchFavourite}
    >
      <BucketSvgIcon src={srcIconName} className="text-black h-6 w-6" />
    </div>
  )
}

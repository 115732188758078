export const FAVOURITES_KEY = 'FAVOURITES_KEY'

export const parseFavouritesKey = () => {
  try {
    const favourites = JSON.parse(window.sessionStorage.getItem(FAVOURITES_KEY))
    if (!favourites?.length) {
      return []
    }
    return favourites
  } catch (_) {
    return []
  }
}

export const deleteVehicleSessionStorage = (id) => {
  return new Promise((resolve) => {
    if (!id) resolve()

    const previousVehicleId = parseFavouritesKey()

    window.sessionStorage.setItem(
      FAVOURITES_KEY,
      JSON.stringify(previousVehicleId.filter((vehicleId) => vehicleId !== id))
    )

    resolve()
  })
}

export const saveVehicleSessionStorage = (id) => {
  if (!id) return
  const previousVehicleId = parseFavouritesKey()

  if (!previousVehicleId.includes(id)) {
    window.sessionStorage.setItem(
      FAVOURITES_KEY,
      JSON.stringify([...previousVehicleId, id])
    )
  }
}

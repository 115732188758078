import { VALUE_ZERO } from './constants'
import { Constants } from 'f1-utils'
import settings from '../settings'
import passwordComplexityChecker from 'pwd-strength'
import _ from 'lodash'

const { DOCUMENT_AUTHENTICATION_PROVIDERS } = Constants

export const isZeroOrGreater = (value) => {
  return value >= VALUE_ZERO
}

export const isNumberType = (value) => {
  const typeOfNumber = 'number'

  return typeof value === typeOfNumber
}

export const isEmptyJSON = (jsonObject) => {
  const keysArray = Object.keys(jsonObject)
  const isEmpty = !keysArray.length

  return isEmpty
}

export const isCO2EmissionsDataEnabled = () => {
  const co2EmissionsDataEnabled = Boolean(
    settings.emissionsData && settings.emissionsData.labelData
  )

  return co2EmissionsDataEnabled
}

export const isValidPassword = (password) => {
  const passwordConfig = settings?.subscriberPasswordComplexityRules
  const passwordCheckerResult = passwordComplexityChecker(
    password,
    passwordConfig
  )

  return passwordCheckerResult.success
}

export const isEqualArrays = (fisrtArray = [], secondArray = []) => {
  return _.isEqual(fisrtArray, secondArray)
}

export const isValidDate = (dateObject) => {
  return dateObject instanceof Date && !isNaN(dateObject)
}

export const isNil = (value) => {
  return _.isNil(value)
}

export const isUndefined = (value) => {
  return _.isUndefined(value)
}

export const isNull = (value) => {
  return _.isNull(value)
}

export const isAuthProviderElectronicIdEnabled = (authProvider) => {
  return authProvider === DOCUMENT_AUTHENTICATION_PROVIDERS.electronicId
}

export const isAuthProviderElectronicIdMediumEnabled = (authProvider) => {
  return authProvider === DOCUMENT_AUTHENTICATION_PROVIDERS.electronicIdMedium
}

export const isAuthProviderElectronicIdHighEnabled = (authProvider) => {
  return authProvider === DOCUMENT_AUTHENTICATION_PROVIDERS.electronicIdHigh
}

export const isAuthProviderFileUploadEnabled = (authProvider) => {
  return authProvider === DOCUMENT_AUTHENTICATION_PROVIDERS.fileUpload
}

export const isAuthProviderBankIdEnabled = (authProvider) => {
  return authProvider === DOCUMENT_AUTHENTICATION_PROVIDERS.bankId
}

export const isElectronicIdSignatureEnabled = () => {
  return settings.digitalSignatureEnabled
}
export const isAValidURL = (url) => {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'
import { isNativeiOS } from './native/nativeUtils'
import { TRACKING_TRANSPARENCY_STATUS } from './constants'

export async function getTrackingStatus() {
  if (isNativeiOS()) {
    const response = await AppTrackingTransparency.getStatus()

    return response.status
  } else {
    return TRACKING_TRANSPARENCY_STATUS.authorized
  }
}

export async function requestTrackingPermission() {
  if (isNativeiOS()) {
    const response = await AppTrackingTransparency.requestPermission()

    return response.status
  } else {
    return TRACKING_TRANSPARENCY_STATUS.authorized
  }
}

import { getCountryFromPath } from './urlHelper'
import { VALUES } from './constants'

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

function getCacheKeyByCountry(cacheKey) {
  const countryCode = getCountryFromPath().toUpperCase()
  const cacheKeyByCountry = [countryCode, cacheKey].join(VALUES.slash)

  return cacheKeyByCountry
}

function checkExpiry(item) {
  const isItemJson = isJson(item)
  const parsedItem = item && isItemJson ? JSON.parse(item) : null
  return parsedItem?.expiry && Date.now() > parsedItem?.expiry
}

class StorageByCountry {
  constructor(storage) {
    this.storage = storage
  }

  getItem(cacheKey) {
    const cacheKeyByCountry = getCacheKeyByCountry(cacheKey)
    let item = this.storage.getItem(cacheKeyByCountry)
    if (checkExpiry(item)) {
      this.removeItem(cacheKey)
      return null
    }
    return item
  }

  setItem(cacheKey, value) {
    const cacheKeyByCountry = getCacheKeyByCountry(cacheKey)

    this.storage.setItem(cacheKeyByCountry, value)
  }

  removeItem(cacheKey) {
    const cacheKeyByCountry = getCacheKeyByCountry(cacheKey)

    this.storage.removeItem(cacheKeyByCountry)
  }
}

export const localStorageByCountry = new StorageByCountry(localStorage)
export const sessionStorageByCountry = new StorageByCountry(sessionStorage)

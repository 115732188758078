import { Apps, PLATFORMS, VALUES } from './constants'
import { App } from '@capacitor/app'
import { getCountryFromPath } from './urlHelper'
import { getPlatform as getPlatformDevice } from './native/nativeUtils'
import settings from '../settings'

export const getPlatform = () => {
  return Apps.WEBAPP
}

const getHostAndCountry = () => {
  const host = window.location.host
  const country = getCountryFromPath() || settings.country?.toLowerCase()
  const hostAndCountryUrl = [host, country].join(VALUES.slash)

  return hostAndCountryUrl
}

export const getAppId = async () => {
  let appId

  if (getPlatformDevice() === PLATFORMS.web) appId = getHostAndCountry()
  else appId = `${(await App.getInfo()).id}/${getPlatformDevice()}`

  return appId
}

import React from 'react'
import { Spinner } from 'asb-frontend'
import SpinnerOverlay from '../../components/Spinner/SpinnerOverlay'

export default function LoadingLayout() {
  return (
    <div style={{ paddingTop: '2rem' }}>
      <SpinnerOverlay show={true} />
    </div>
  )
}

import getWhenAvailableText from '../../util/getWhenAvailable'
import { BALLOON } from '../../util/constants'
import settings from '../../settings'
import { Skeleton } from 'asb-frontend'

export default function ProductTypeTag(props) {
  const { children, t, vehicle } = props

  if (settings.productTypes.length <= 1) {
    return <></>
  }
  const pricing = vehicle?.pricing

  const whenAvailable = pricing ? getWhenAvailableText(null, t, vehicle) : null

  const productType = pricing && pricing[0].productType

  const balloonStyle =
    'flex flex-none gap-1 items-center bg-gray-800 rounded-l-full rounded-r-full px-2 py-1 text-white max-w-max'
  const subscriptionStyle =
    'flex flex-none gap-1 items-center bg-primary-light rounded-l-full rounded-r-full px-2 py-1 text-primary max-w-max'
  const tagStyle = productType === BALLOON ? balloonStyle : subscriptionStyle

  return (
    <Skeleton
      conditionToShow={!vehicle || !whenAvailable}
      sx={{ borderRadius: '100px' }}
      width="18%"
      height="25px"
    >
      <div className={`flex gap-2 mb-1 w-full srp-available-bar`}>
        <div className={tagStyle}>
          {children}
          <p className="text-xs">{whenAvailable}</p>
        </div>
      </div>
    </Skeleton>
  )
}

import { useEffect } from 'react'
import settings from '../settings'
import { omitNotch } from './helper'
import { CapacitorCookies } from '@capacitor/core'
import { isNativePlatform } from './native/nativeUtils'
import { requestTrackingPermission } from './trackingTransparency'
import { TRACKING_TRANSPARENCY_STATUS } from './constants'

const oneTrustSdkButtonId = 'ot-sdk-btn'
const htmlTagScript = 'script'
const typeJavascript = 'text/javascript'
const HEADER_CLASSNAME = 'pc-header'
const PADDING_TOP = '3rem'
const prodDomain = '.wabicar.com'

const oneTrustScriptIds = {
  SDK: 'ot-sdk',
  optanonWrapper: 'ot-optanonWrapper',
}

const duplicateExternalCookies = async () => {
  if (isNativePlatform()) {
    try {
      const domainCookies = await CapacitorCookies.getCookies({
        url: prodDomain,
      })
      for (const [key, value] of Object.entries(domainCookies)) {
        CapacitorCookies.setCookie({ key, value })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export function OneTrustButton() {
  useEffect(async () => {
    const trackingPermission = await requestTrackingPermission()

    if (trackingPermission === TRACKING_TRANSPARENCY_STATUS.authorized) {
      const oneTrustWebappId = settings.oneTrustWebappId
      insertOneTrustScripts(oneTrustWebappId)
    }
  }, [])

  return (
    <button id={oneTrustSdkButtonId} className="ot-sdk-show-settings hidden" />
  )
}

export function openOneTrustModal() {
  document.getElementById(oneTrustSdkButtonId)?.click()
  if (omitNotch)
    document.getElementsByClassName(HEADER_CLASSNAME)[0].style.paddingTop =
      PADDING_TOP
}

function getOtSDKScript(oneTrustWebappId) {
  const srcOtSDK = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'

  const scriptOtSDK = document.createElement(htmlTagScript)

  scriptOtSDK.id = oneTrustScriptIds.SDK
  scriptOtSDK.type = typeJavascript
  scriptOtSDK.src = srcOtSDK
  scriptOtSDK.charset = 'UTF-8'
  scriptOtSDK.setAttribute('data-document-language', 'true')
  scriptOtSDK.setAttribute('data-domain-script', oneTrustWebappId)

  return scriptOtSDK
}

function getOptanonWrapperScript() {
  const optanonWrapperCodeText = ' function OptanonWrapper() {} '

  const scriptOptanonWrapper = document.createElement(htmlTagScript)
  const optanonWrapperCode = document.createTextNode(optanonWrapperCodeText)

  scriptOptanonWrapper.id = oneTrustScriptIds.optanonWrapper
  scriptOptanonWrapper.type = typeJavascript
  scriptOptanonWrapper.appendChild(optanonWrapperCode)

  return scriptOptanonWrapper
}

function scriptExistsInHTML(elementId) {
  const scriptElement = document.getElementById(elementId)

  return scriptElement ? true : false
}

export function insertOneTrustScripts(oneTrustWebappId) {
  if (oneTrustWebappId) {
    if (!scriptExistsInHTML(oneTrustScriptIds.SDK)) {
      const scriptOtSDK = getOtSDKScript(oneTrustWebappId)
      document.head.appendChild(scriptOtSDK)
    }

    if (!scriptExistsInHTML(oneTrustScriptIds.optanonWrapper)) {
      const scriptOptanonWrapper = getOptanonWrapperScript()
      document.head.appendChild(scriptOptanonWrapper)
    }

    duplicateExternalCookies()
  }
}

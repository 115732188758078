import { useEffect, useState } from 'react'
import { PLATFORMS, TRANSLATIONS, downloadAppTranslations } from '../util/constants'
import { navigateToExternalLink } from '../util/navigateToExternalLink'
import { getOperatingSystem } from '../util/native/nativeUtils'
import { useTranslation } from 'react-i18next'
import { tagManagerTrackAction } from '../tracking/trackingFooter'
import { trackingLiterals } from '../setup/tracking/trackingLiterals'

const downloadAppInNewTab = true

export default function useNativeDownloadApp() {
  const { t: urlTranslation } = useTranslation(TRANSLATIONS.URLS)
  const [operatingSystem, setOperatingSystem] = useState()

  useEffect(async () => {
    const os = await getOperatingSystem()
    setOperatingSystem(os)
  }, [])
  
  const onClickDownloadGooglePlay = () => {
    tagManagerTrackAction(
      trackingLiterals.events.actions.button,
      trackingLiterals.events.download_google_play
    )
    const urlAppGooglePlay = urlTranslation(downloadAppTranslations.googlePlay)
    navigateToExternalLink(urlAppGooglePlay, downloadAppInNewTab)
  }

  const onClickDownloadAppStore = () => {
    tagManagerTrackAction(
      trackingLiterals.events.actions.button,
      trackingLiterals.events.download_app_store
    )
    const urlAppAppStore = urlTranslation(downloadAppTranslations.appStore)
    navigateToExternalLink(urlAppAppStore, downloadAppInNewTab)
  }

  const onClickNativeDownload = () => {
    if(operatingSystem === PLATFORMS.ios)
      onClickDownloadAppStore()
    if(operatingSystem === PLATFORMS.android)
      onClickDownloadGooglePlay()
  }

  return { onClickNativeDownload, onClickDownloadAppStore, onClickDownloadGooglePlay, operatingSystem }
}
import { useEffect } from 'react'
import useFetchLanguage from './useFetchLanguage'
import useAppState from './useAppState'
import useCustomNavigate from './useCustomNavigate'
import i18n from '../setup/i18n'
import { LanguageConfig } from 'f1-utils'
import { NEED_TO_CHANGE_LANGUAGE_EVENT } from '../util/constants'
import { LANGUAGE_CONFIG } from '../util/languagesManager'
import { getLanguageFromPath } from '../util/urlHelper'

const isLanguageAllowed = (languageCode) => {
  const availableLanguages = LANGUAGE_CONFIG.availableLanguages

  if (!LanguageConfig.I18_MAPPING_REVERSE[languageCode]) return false

  for (let i = 0; i < availableLanguages.length; i++) {
    const availableLanguageCode =
      LanguageConfig.I18_MAPPING[availableLanguages[i].languageConstant]

    if (availableLanguageCode === languageCode) {
      return true
    }
  }

  return false
}

const isLanguageMissmatch = (userLanguage) => {
  const isSameLanguage =
    LanguageConfig.I18_MAPPING[userLanguage] === i18n.language

  return userLanguage && !isSameLanguage
}

const handleLanguageMissmatch = () => {
  window.dispatchEvent(new Event(NEED_TO_CHANGE_LANGUAGE_EVENT))
}

export default function useLanguageManager() {
  const { isLoggedIn } = useAppState()
  const [getUserLang, selectedUserLang] = useFetchLanguage()
  const navigate = useCustomNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      getUserLang()
    }
  }, [isLoggedIn])

  useEffect(() => {
    const isI18nLanguageAllowed = isLanguageAllowed(i18n.language)

    const handleInvalidLanguage = () => {
      let newLanguage

      if (!isLoggedIn) {
        newLanguage = isI18nLanguageAllowed
          ? i18n.language
          : LANGUAGE_CONFIG.defaultLanguage
      } else if (selectedUserLang) {
        newLanguage = LanguageConfig.I18_MAPPING[selectedUserLang]
      }

      if (newLanguage) {
        i18n.changeLanguageAndUrl(newLanguage, navigate)
      }
    }

    if (!isI18nLanguageAllowed || !getLanguageFromPath()) {
      handleInvalidLanguage()
    } else if (isLanguageMissmatch(selectedUserLang)) {
      handleLanguageMissmatch()
    }
  }, [selectedUserLang])
}

import React from 'react'
import { isNativePlatform } from '../../util/native/nativeUtils'

const skipOnboardingCarouselKey = 'skipOnboardingCarousel'

export const shouldShowOnboardingCarousel = () => {
  return !localStorage.getItem(skipOnboardingCarouselKey)
}

export const setShowOnboardingCarousel = () => {
  return localStorage.setItem(skipOnboardingCarouselKey, 1)
}

export const useShouldShowOnboardingCarousel = () => {
  return isNativePlatform() && shouldShowOnboardingCarousel()
}

export const isReactFragment = (child) => {
  return child?.type?.toString() === React.Fragment.toString()
}

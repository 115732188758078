import axios from 'axios'
import { sleep } from './sleep'
import { CACHE_KEYS } from './constants'
import { localStorageByCountry } from './storageByCountry'

function getCachedRequests() {
  const cachedRequestsData = localStorageByCountry.getItem(
    CACHE_KEYS.storedRequests
  )

  const cachedRequests = cachedRequestsData
    ? JSON.parse(cachedRequestsData)
    : []

  return cachedRequests
}

async function fetchRequest(url) {
  const response = await axios.get(url)

  return response
}

async function fetchAndCacheRequest(url) {
  const request = { url, isFetching: true }
  cacheNewRequest(request)

  try {
    const response = await fetchRequest(url)

    request.responseData = response?.data
  } catch (error) {
    request.error = error.message
  }

  request.isFetching = false
  updateRequest(request)

  return request
}

function cacheNewRequest(newRequest) {
  const cachedRequests = getCachedRequests()
  const requests = [...cachedRequests, newRequest]
  const requestsData = JSON.stringify(requests)

  localStorageByCountry.setItem(CACHE_KEYS.storedRequests, requestsData)
}

function updateRequest(updatedRequest) {
  const cachedRequests = getCachedRequests()

  const updatedRequests = cachedRequests.map((request) => {
    if (request.url === updatedRequest.url) {
      return updatedRequest
    }

    return request
  })

  const requestsData = JSON.stringify(updatedRequests)

  localStorageByCountry.setItem(CACHE_KEYS.storedRequests, requestsData)
}

export async function cacheOrFetchRequest(url) {
  const retryWaitMilliseconds = 100

  let cachedRequests
  let cachedCurrentRequest
  let retry = false

  do {
    cachedRequests = getCachedRequests()
    cachedCurrentRequest = cachedRequests.find((request) => request.url === url)

    if (cachedCurrentRequest?.isFetching) {
      retry = true
      await sleep(retryWaitMilliseconds)
    } else {
      retry = false
    }
  } while (retry)

  const response = cachedCurrentRequest
    ? cachedCurrentRequest
    : await fetchAndCacheRequest(url)

  const { error } = response

  if (error) {
    throw new Error(error)
  }

  return response.responseData
}

function isValidCachedRequest(cachedRequest) {
  const { isFetching, error } = cachedRequest

  if (isFetching || error) {
    return false
  }

  return true
}

export function cleanInvalidRequests() {
  const cachedRequests = getCachedRequests()

  const updatedRequests = []

  cachedRequests.forEach((cachedRequest) => {
    if (isValidCachedRequest(cachedRequest)) {
      updatedRequests.push(cachedRequest)
    }
  })

  const requestsData = JSON.stringify(updatedRequests)

  localStorageByCountry.setItem(CACHE_KEYS.storedRequests, requestsData)
}

import { Transition } from '@headlessui/react'
import { forwardRef, Fragment } from 'react'

const transitionInOut = 'transition ease-in-out duration-300'
const opacityZero = 'opacity-0'
const opacityFull = 'opacity-100'

const FragmentOpacityTransition = forwardRef(({ children, ...props }, ref) => {
  return (
    <Transition
      ref={ref}
      {...props}
      as={Fragment}
      enter={transitionInOut}
      enterFrom={opacityZero}
      enterTo={opacityFull}
      leave={transitionInOut}
      leaveFrom={opacityFull}
      leaveTo={opacityZero}
    >
      {children}
    </Transition>
  )
})

export default FragmentOpacityTransition

import { useCallback } from 'react'
import { useParams } from 'react-router'
import { getDefaultCountryCode } from '../util/countryHelper'
import DOMPurify from 'dompurify'

export default function useBuildUrl() {
  const { lang, countryCode } = useParams()
  const navigateCountryCode = countryCode || getDefaultCountryCode()
  const sanitizedLang = DOMPurify.sanitize(lang)
  const sanitizedCountryCode = DOMPurify.sanitize(navigateCountryCode)

  const buildUrl = useCallback(
    (internalPath) =>
      `/${sanitizedCountryCode}/${sanitizedLang}${internalPath}`,
    [lang, navigateCountryCode]
  )

  return buildUrl
}

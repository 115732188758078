import { gql, useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import useAppState from './useAppState'

const GET_SELECTED_LANGUAGE_CONSTANT = gql`
  {
    selectedLanguageConstant
  }
`

export default function useFetchLanguage() {
  const [lang, setLang] = useState(null)
  const { isLoggedIn } = useAppState()
  const [getLang, { loading }] = useLazyQuery(GET_SELECTED_LANGUAGE_CONSTANT, {
    onCompleted: async ({
      selectedLanguageConstant: storedLanguageConstant,
    }) => {
      setLang(storedLanguageConstant)
    },
  })

  const getUserLang = () => {
    if (isLoggedIn) {
      getLang()
    }
  }

  return [getUserLang, lang, loading]
}

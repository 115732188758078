import {
  CACHE_KEYS,
  LOCAL_BUCKET_URL,
  QUERY_PARAM_NAMES,
  VALUES,
} from '../../util/constants'
import { localStorageByCountry } from '../../util/storageByCountry'
import { addParametersToUrl } from '../../util/urlHelper'

const getUrlParameters = (assetVersion) => {
  const parameters = []

  if (assetVersion) {
    parameters.push({
      name: QUERY_PARAM_NAMES.assetVersion,
      value: assetVersion,
    })
  }

  return parameters
}

export function buildAssetUrl(
  assetList = [],
  assetName,
  assetBucketUrl,
  manifestVersion
) {
  const asset = assetList?.find((asset) => asset.name === assetName)

  let assetUrl

  if (asset) {
    const baseUrl = assetBucketUrl + asset.value.src

    const parameters = getUrlParameters(manifestVersion)

    assetUrl = addParametersToUrl(baseUrl, parameters)
  }

  return { ...asset, url: assetUrl }
}

function getAssetUrl(assets, fallbackAssets, assetName, bucketUrl) {
  const assetList = assets || []
  const fallbackAssetList = fallbackAssets || []
  const manifestVersion = localStorageByCountry.getItem(
    CACHE_KEYS.manifestVersion
  )
  const fallbackManifestVersion = localStorageByCountry.getItem(
    CACHE_KEYS.fallbackManifestVersion
  )

  let assetUrl = buildAssetUrl(assetList, assetName, bucketUrl, manifestVersion)

  if (!assetUrl.url) {
    assetUrl = buildAssetUrl(
      fallbackAssetList,
      assetName,
      LOCAL_BUCKET_URL,
      fallbackManifestVersion
    )
  }

  return assetUrl
}

export function getAssetSrc({
  assets,
  fallbackAssets,
  assetName,
  altAssetName,
  bucketUrl,
  assetType,
}) {
  const assetList = assets[assetType] || []
  const fallbackAssetList = fallbackAssets[assetType] || []

  let assetSrc = getAssetUrl(assetList, fallbackAssetList, assetName, bucketUrl)

  if (!assetSrc?.url && altAssetName) {
    assetSrc = getAssetUrl(
      assetList,
      fallbackAssetList,
      altAssetName,
      bucketUrl
    )
  }

  if (!assetSrc?.url) {
    assetSrc = { url: VALUES.empty }
  }

  return assetSrc
}
